import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { projectAuth } from '../firebase'

const requireAuth = (to, from, next) => {
	let user = projectAuth.currentUser

	if (!user) {
		next({ name: 'home' })
	} else {
		next()
	}
}

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/login-23',
		name: 'Login',
		component: () => import('../views/LoginView.vue')
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/LogoutView.vue')
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('../views/DashboardView.vue'),
		beforeEnter: requireAuth,
		children: [
			{
				path: '',
				name: 'Dashboard Index',
				component: () => import('../views/DashboardIndex.vue'),
				meta: {
					routeClass: 'dashboard-index'
				}
			},
			{
				path: 'shows',
				name: 'Dashboard Shows',
				component: () => import('../views/DashboardShows.vue'),
				meta: {
					routeClass: 'dashboard-shows'
				}
			},
			{
				path: 'extra',
				name: 'Dashboard Extra',
				component: () => import('../views/DashboardExtra.vue'),
				meta: {
					routeClass: 'dashboard-extra'
				}
			},
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
