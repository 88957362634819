<template>
	<router-view />
</template>
<style>
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;*font-size:100%}legend{color:#000}

html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}
html,body {font-size: 62.5%;}

@font-face {
	font-family: "BNTillsdale Regular";
	src: url(./assets/font/BNTillsdale_Family/BNTillsdale.otf);
}

@font-face {
	font-family: "BNTillsdale Light";
	src: url(./assets/font/BNTillsdale_Family/BNTillsdale-Light.otf);
}

:root {
	--amber: #FFBA00;
	--azure: #009ADF;
	--brightOrange: #FF681E;
	--darkBlue: #003CA5;
	--darkPurple: #5C0B8A;
	--darkTangerine: #F7A300;
	--fuchsia: #9E29B5;
	--grey: #525252;
	--tomatoRed: #E33D2E;
	--white: #fff;
}

.response-msg,
#i-agree .flex {
	align-items: center;
}

body,
#pledges-counter {
	background-color: var(--azure);
}
#pledges h5 {
	background-color: var(--amber);
}

.pg header,
#pledge-modal-close {
	background-color: var(--white);
}

.pg button,
.form-container button,
#pledge-modal-close span {
	background-color: var(--tomatoRed);
}

#pledge {
	background-color: var(--darkTangerine);
}

.pg button:hover,
.form-container button:hover {
	background-color: var(--fuchsia);
}

.form-container textarea {
	background-color: rgba(0, 60, 165,.21);
}

#login,
#logout {
	background-color: #F2E7D1;
}

#pledge-modal-inner {
	background: rgba(0, 154, 223, 0.93);
}

#hero {
	background-image: url(./assets/img/hands-static.png);
}

#logo,
#hero {
	background-repeat: no-repeat;
}

#pledges-counter,
.pg footer {
	background-repeat: repeat-x;
}

#logo,
#hero,
.pg footer {
	background-position: center;
}

#pledges-counter {
	background-position: center bottom -21px;
}

.pg footer {
	background-position: center bottom -1px;
}

#logo {
	background-size: contain;
}

#hero {
	background-size: cover;
}

#pledges-counter,
footer {
	background-size: 540px;
}

.pg button,
.form-container button,
.form-container input,
.form-container textarea {
	border: 0 none;
}

#pledge-modal-inner {
	border: 1px solid rgba(255,255,255,.33)
}

.db-wrp form,
.db-wrp input,
.db-wrp button {
	border: 2px solid var(--darkBlue);
}

.zeros + .odometer .odometer-digit:last-child {
	border-right: 1px solid var(--brightOrange);
}

.odometer .odometer-digit {
	border-left: 1px solid var(--brightOrange);
}

#pledge-modal-close {
	border-radius: 50%;
}

.pg button,
.form-container button {
	box-shadow: 3px 5px 1px 0 #af3222;
}

.pg button:hover,
.form-container button:hover {
	box-shadow: 3px 5px 1px 0 #541785;
}

.pg button,
.form-container textarea,
.form-container button,
.pg section ul,
#hero h2,
.hdr h3,
#hero,
#pledge-modal-container,
.hdr p,
.pg footer p {
	color: var(--white);
}

.ext-link a span,
.pg button span + span,
p.notes a {
	color: var(--amber);
}

.form-container input:focus {
	color: var(--darkBlue);
}

#stats,
#ask,
#more {
	color: var(--darkPurple);
}

section .container,
#hero h2,
#pledges h5,
#counter {
	color: var(--tomatoRed);
}

#pledge-chance-container a {
	color: var(--azure);
}

.form-container input,
.form-container input::placeholder {
	color: #A4A4A4;
}


button,
#pledge-modal-close {
	cursor: pointer;
}

.flex,
.pg header,
#hero .container,
section .container:not(.hdr),
#i-agree,
#pledge-modal-inner,
#info-form,
#pledges-counter .container,
#counter > div {
	display: flex;
}

#login,
#logout,
.pg header,
#pledge-modal-close,
#counter {
	display: grid;
}

.pg button span,
.form-container button,
button span,
#pledge-modal-close span {
	display: block;
}

#i-agree {
	flex-direction: column;
}

body .pg,
.pg button,
.form-container button,
#pledge-modal-container,
.pg footer p {
	font-family: "BNTillsdale Regular";
}

.pg section .hdr,
#form-instructions h2 + h3,
.form-container input,
.form-container button,
p.notes {
	font-family: "BNTillsdale Light";
}


.form-container textarea,
.pg footer p span {
	font-family: Arial, Helvetica, sans-serif;
}

#login,
#logout {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#xl {
	font-size: 17.1rem;
}

#pledge-modal-container h2 {
	font-size: 7.2rem;
}

#counter {
	font-size: 6.6rem;
}

section h2 {
	font-size: 5.4rem;
}

.pg button span,
.form-container button span,
#pledge-modal-container h3,
#form-submitted h2 + p {
	font-size: 4.8rem;
}

#hero h2 {
	font-size: 5.91rem;
}

#pledge-chance-container a,
.pg section .hdr h3 {
	font-size: 3.6rem;
}

footer p {
	font-size: 3.2rem;
}

.pg section h3,
.pg section ul,
.pg section p.ext-link {
	font-size: 3.0rem;
}

.pg section p {
	font-size: 2.4rem;
}

#pledges h5 {
	font-size: 2.25rem;
}

.pg section .hdr p,
.form-container input {
	font-size: 2.2rem;
}

#hero p,
#i-agree .flex {
	font-size: 2rem;
}

.pg button span + span {
	font-size: 1.75rem;
}

#login,
#logout {
	font-size: 1.65rem;
}

p.notes {
	font-size: 1rem;
}

.chkbox textarea {
	font-size: .9rem;
}

.pg section .container {
	gap: 40px;
}

#pledge-modal-inner {
	gap: 35px;
}

#i-agree .flex {
	gap: 12px;
}

#i-agree {
	gap: 9px;
}

#hero .container {
	height: 100%;
}

.pg button {
	height: 103px;
}

.form-container button {
	height: 90px;
}

#counter > div {
	height: 84px;
}

.form-container input {
	height: 62px;
}

.chkbox textarea {
	height: 51px;
}

#pledge-modal-close span {
	height: 1px;
}

#pledges-counter .container,
.response-msg {
	justify-content: center;
}

#pledge-button-container,
#counter,
#pledge-chance-container,
#pledge-modal-container {
	left: 50%;
}

#pledges h5 {
	letter-spacing: 0.11em;
}

#xl {
	letter-spacing: -0.3rem;
}

input {
	line-height: 3;
}

#pledges h5 {
	line-height: 2;
}

.odometer-digit li {
	line-height: 1.5;
}

.pg section .hdr h3 + p,
.pg section ul,
.pg footer p {
	line-height: 1.25;
}

#hero p,
p.notes {
	line-height: 1.2;
}

.pg section .hdr h3 {
	line-height: 1.1;
}

.pg butotn span,
section h2,
.pg section h3,
#pledge-modal-container h3,
#form-submitted h2 + p {
	line-height: 1;
}

.pg button span,
.form-container button span,
#form-instructions h2 {
	line-height: .93;
}

#xl {
	line-height: .741;
}

#hero h2 {
	line-height: .81;
}

#hero p + h2 {
	margin-top: 21px;
}

section .hdr p + h2,
#form-instructions h2 + h3,
#pledge-modal-inner h3 + p,
#pledge-modal-inner p + h3 {
	margin-top: 15px;
}

#hero p + h2 {
	margin-bottom: 30px;
}

.form-container .field-group {
	margin-bottom: 18px;
}

section h3 + ul {
	margin-top: 38px;
}

#form-submitted h2 + p {
	margin-top: 18px;
}

button span:first-child {
	margin-top: 4px;
}

.pg .container,
section .hdr h3,
section .hdr p {
	margin: 0 auto;
}

#form-instructions h2 + h3 {
	max-width: 90%;
}

.hdr h3 {
	max-width: 850px;
}

section .copy {
	max-width: 680px;
}

#login,
#logout {
	min-height: 100vh;
}

.container {
	max-width: 1280px;
}

#form-submitted {
	max-width: 933px;
}

.form-container {
	min-width: 405px;
}

#pledge-modal-inner {
	overflow: hidden;
}

input::placeholder {
	overflow: visible;
}

#pledge + #pledges-counter #pledges {
	padding-top: 95px;
}

#pledges,
#pledge + #pledges-counter.adjust-counter #pledges {
	padding-top: 33px;
}

#form-instructions {
	padding-top: 12px;
}

.container {
	padding-right: 60px;
}

#additional-info section {
	padding-bottom: 78px;
}

#additional-info section:last-child {
	padding-bottom: 0;
}

#form-instructions h2 {
	padding-left: 72px;
}

.container {
	padding-left: 60px;
}

#additional-info {
	padding: 56px 0;
}

.db-wrp input,
.db-wrp button {
	padding: 9px 12px;
}

.form-container input {
	padding: 0 26px;
}

#pledge-modal-inner {
	padding: 48px 36px 36px 48px;
}

#pledge-chance-container {
	padding: 30px 36px;
}

.chkbox textarea {
	padding: 3px 9px;
}

section h2 {
	padding: 6px 0 0;
}

#login,
#logout,
.pg header,
#hero .container,
#counter {
	place-items: center;
}

#pledge-modal-close {
	place-content: center;
}

.pg header,
#pledge-modal-container {
	position: fixed;
}

#pledge-button-container,
#counter,
#pledge-chance-container,
#pledge-modal-close {
	position: absolute;
}

#pledge-modal-inner,
#pledges {
	position: relative;
}

#pledge-modal-close {
	right: 60px;
}

#form-submitted,
#pledges h5,
.pg section .hdr h3 + p,
.hdr h3,
.odometer-digit,
.pg footer p {
	text-align: center;
}

section li {
	text-transform: capitalize;
}

#form-instructions h2,
#form-submitted h2 {
	text-transform: uppercase;
}

#pledge-modal-container {
	top: 146px;
}

#pledge-button-container,
#counter,
#pledge-chance-container,
#pledge-modal-container {
	transform: translateX(-50%);
}

#pledge-modal-close {
	transform: translate(50%);
}

#i-pledge {
	transform: translateY(calc(-50% - 3px));
}

#pledge-modal-close span {
	transform: rotate(-45deg);
}

#pledge-modal-close span + span {
	transform: rotate(45deg);
}

.pg button,
.form-container button,
.pg button span {
	transition: all 180ms ease-in-out;
}

#hero .copy,
#additional-info  .hdr h2,
#pledge-chance-container {
	text-align: center;
}

.pg h1 {
	text-indent: -9630rem;
}

#hero #xl,
#hero h2,
.pg section h2,
.pg section :not(.hdr) h3,
button span:first-child {
	text-transform: uppercase;
}

.pg header {
	top: 0;
}

#pledge-modal-close {
	top: -21px;
}

.pg button,
.form-container button,
.pg header,
#pledge-button-container,
#pledge-modal-container,
.form-container input,
#pledge-chance-container,
.chkbox textarea {
	width: 100%;
}

#pledge-modal-close span,
input[type=checkbox] {
	width: 28px;
}

.pg header {
	z-index: 9;
}

#pledge-modal-close,
#pledge-chance-container,
#pledge-button-container {
	z-index: 1;
}




#pledge-modal-inner {
	backdrop-filter: blur(6px);
}

#pledge-modal-inner button[disabled] {
	background-color: #A4A4A4;
	box-shadow: 3px 5px 1px 0 #686868;
}

.pg header {
	background: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(6px);
	border-bottom: 1px solid rgba(255,255,255,.42);
	height: 90px;
}

	.pg header + main {
		margin-top: 90px;
	}

	#logo {
		background-image: url(./assets/img/northwell-health-logo.png);
		width: 198px;
		height: 48px;
	}

#hero {
	background-color: var(--darkTangerine);
	background-repeat: repeat-x;
	background-position: center bottom;
	background-size: 1050px;
	height: 486px;
}

	#hero .copy {
		margin: -102px auto 0;
		width: auto;
	}

	#hero h2 {
		margin-top: -6px;
	}

		#hero h2 .line-text + .q {
			margin-right: -.6ch;
		}

#pledges h5 {
	border-radius: 25px;
	width: 200px;
	height: 60px;
}

#counter {
	background: url(./assets/svg/rectangle.svg) no-repeat center center;
	background-size: contain;
	height: 128px;
	width: 425px;
	margin-top: -24px;
}

#info-form {
	height: 0;
	transition: height 247ms linear;
}

.show-overlay #info-form {
	height: 426px;
}

.concert-happening .show-overlay #info-form {
	height: 376px;
}

.show-overlay.response-msg #info-form {
	height: 270px;
}

#pledge-modal-close {
	width: 42px;
	height: 42px;
}

#form-instructions h2 span {
	position: relative;
}

	#form-instructions h2 span::before {
		background-image: url(./assets/svg/hand.svg);
		background-repeat: no-repeat;
		background-size: contain;
		content: " ";
		display: block;
		width: 76px;
		height: 175px;
		position: absolute;
		/* transform: translate(-114%,-30%); */
		top: -52px;
		left: -84px;
	}

	#i-agree label {
		cursor: pointer;
		display: flex;
		gap: 12px;
		font-size: 1.4rem;
		line-height: 1.5;
	}

	#terms-container {
		padding-left: 26px;
	}

	.form-container input[type=checkbox] {
		cursor: pointer;
		opacity: 0.01;
		position: absolute;
		z-index: 1;
	}

	.form-container input[type=checkbox] + span.cb {
		background-color: var(--white);
		border: 1px solid var(--grey);
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
	}

	.form-container input[type=checkbox]:checked + span::after {
		background-image: url(./assets/img/checkbox.png);
		background-size: contain;
		bottom: 1px;
		content: " ";
		position: absolute;
		width: 24px;
		height: 20px;
	}


#pledges-counter {
	height: 270px;
}

	/* .loadHands {
		position: absolute;
		left: -9876rem;
		overflow: hidden;
		width: 0;
		height: 0;
	}

	.loadHands,
	#pledges-counter.animate-hands {
		background-image: url(./assets/img/hands-animation.gif);
	} */

#additional-info section:nth-of-type(even) .img {
	margin-left: 237px;
}

section .hdr.container {
	margin-bottom: 85px;
}

	section h3 + h4 {
		margin-bottom: 24px;
	}

	section p {
		max-width: 848px;
	}

	section .copy ul:not(.bull) li::after {
		content: "-";
		display: block;
		line-height: 1.02;
	}

		section .copy ul:not(.bull) li:last-child::after {
			display: none;
		}

section #ask ul + p.ext-link {
	margin-top: 37px;
}

.ext-link a {
	padding-left: 40px;
	position: relative;
	text-decoration: none;
}

	.ext-link a span {
		border-bottom: 2px solid var(--amber);
		display: inline-block;
		line-height: .66;
	}

	.ext-link a:before {
		background: url(./assets/svg/arrow.svg) no-repeat;
		background-size: contain;
		content: "";
		display: inline-block;
		left: 0;
		position: absolute;
		top: 2px;
		width: 20px;
		height: 20px;
	}

ul.bull li {
	margin-bottom: 33px;
}


.pg footer {
	background-image: url(./assets/svg/hands-multi.svg);
	height: 438px;
}

	.pg footer p {
		position: relative;
	}

	.pg footer p:before {
		background-color: var(--white);
		content: " ";
		position: absolute;
		left: 50%;
		margin-left: -23px;
		top: -11px;
		width: 46px;
		height: 1px;
	}





@media screen and (min-width: 1024px) {
	section h2 br {
		display: none;
	}

	section .hdr br {
		display: none;
	}
}



@media screen and (min-width: 1024px) and (max-width: 1279px) {
	#pledge-modal-container h2 {
		font-size: 5.1rem;
	}

	#form-instructions h2 span::before {
		top: -30px;
		left: -69px;
		width: 66px;
		height: 120px;
	}

	#form-instructions h2 + h3 {
		font-size: 3.9rem;
	}

}

@media screen and (min-width: 1024px) and (max-width: 1150px) {
	.form-container {
		min-width: 384px;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1079px) {
	#pledge-modal-container h2 {
		font-size: 4.8rem;
	}

	#form-instructions h2 span::before {
		width: 60px;
		height: 111px;
	}

	#form-instructions h2 + h3 {
		font-size: 3.6rem;
	}

	.form-container {
		min-width: 360px;
	}
}

@media screen and (max-width: 1023px) {

	#pledges-counter,
	footer {
		background-size: 390px;
	}


	#pledges h5 {
		border-radius: 20px;
	}

	.pg section ul br,
	#pledge-modal-inner p br {
		display: none;
	}

	.pg section .container,
	#pledge-modal-inner,
	#info-form {
		flex-direction: column;
	}

	#xl {
		font-size: 6rem;
	}

	#counter {
		font-size: 5.3rem;
	}

	#hero h2,
	.pg button span,
	.form-container button span {
		font-size: 3.8rem;
	}

	#form-submitted h2 {
		font-size: 3.2rem;
	}

	#form-instructions h2 {
		font-size: 3rem;
	}

	.pg section h2,
	.pg section .hdr h3,
	#pledge-chance-container a {
		font-size: 2.4rem;
	}

	#pledge-modal-inner h3,
	.pg section .hdr p {
		font-size: 2.2rem;
	}

	.pg footer p {
		font-size: 2rem;
	}

	#pledges h5,
	#form-instructions h2 + h3,
	#form-submitted h2 + p  {
		font-size: 1.8rem;
	}

	#hero p,
	.pg section h3,
	.pg section .hdr h3 + p,
	.pg section p,
	.pg section ul,
	.pg section p.ext-link,
	#pledge-modal-inner p {
		font-size: 1.6rem;
	}

	.pg button span + span {
		font-size: 1.5rem;
	}

	#pledge-modal-inner p {
		font-size: 1rem;
	}

	#pledge-modal-inner {
		gap: 25px;
	}

	.pg section .container {
		gap: 9px;
	}

	#counter > div {
		height: 69px;
	}

	#pledges h5 {
		height: 50px;
	}

	#hero .container {
		justify-content: center;
	}

	#form-submitted h2 + p {
		line-height: 1.2;
	}

	.pg section h3,
	.pg section .hdr h3 {
		line-height: 1.16;
	}

	#hero p,
	.pg section p,
	.pg section ul,
	#pledge-modal-inner p {
		line-height: 1.13;
	}

	.hdr h3 + p,
	#pledge-modal-inner h3 {
		line-height: 1.09;
	}

	.pg section h2,
	#form-instructions h2 {
		line-height: .816;
	}

	.odometer-digit li {
		line-height: 1.5;
	}

	#hero #xl {
		margin-top: 21px;
	}

	section h3 + ul {
		margin-top: 17px;
	}

	#hero p + h2,
	.hdr h3 + p {
		margin-top: 6px;
	}

	#hero h2 span.q:last-child {
		margin-right: 0;
	}

	.pg section ul {
		margin-right: auto;
	}

	section .hdr.container {
		margin-bottom: 60px;
	}

	ul.bull li {
		margin-bottom: 19px;
	}

	.form-container .field-group {
		margin-bottom: 13px;
	}

	#additional-info section:nth-of-type(2n) .img {
		margin-left: 0;
	}

	.pg section ul {
		margin-left: auto;
	}

	section p,
	section .copy {
		margin: 0 auto;
	}

	.pg section ul {
		max-width: 306px;
	}

	.pg section #more ul {
		max-width: 360px;
	}

	.form-container {
		min-width: 200px;
	}

	#hero .container {
		padding-top: 26px;
	}

	#pledge + #pledges-counter #pledges {
		padding-top: 66px;
	}

	#pledge + #pledges-counter.adjust-counter #pledges {
		padding-top: 24px;
	}

	p.notes {
		padding-top: 9px;
	}

	.container {
		padding-right: 22px;
	}

	#hero .container {
		padding-right: 12px;
	}

	#pledge-modal-inner .info,
	#pledge-modal-inner:not(.response-msg) .info {
		padding-right: 0;
	}

	#additional-info section {
		padding-bottom: 67px;
	}

	#additional-info section:last-child {
		padding-bottom: 24px;
	}

	.pg section h2 {
		padding-bottom: 6px;
	}

	.container {
		padding-left: 22px;
	}

	#hero .container {
		padding-left: 12px;
	}

	#additional-info {
		padding: 30px 0;
	}

	#pledge-modal-inner {
		padding: 15px;
	}

	.odometer-digit {
		padding: 0 10px;
	}

	#hero .container {
		place-items: flex-start;
	}

	#pledge-modal-close {
		right: 24px;
	}

	.pg section .container > div {
		text-align: center;
	}

	#pledge-modal-container {
		top: 102px;
	}

	#pledges h5 {
		width: 160px;
	}



	.show-overlay #info-form {
		height: 552px;
	}

	.concert-happening .show-overlay #info-form {
		height: 543px;
	}

	.show-overlay.response-msg #info-form {
		height: 156px;
	}

	.pg header {
		height: 76px;
	}

		.pg header + main {
			margin-top: 76px;
		}

		#logo {
			width: 166px;
			height: 40px;
		}

	#hero {
		height: 369px;
	}

		#hero .copy {
			margin-top: 42px;
		}

		#xl {
			letter-spacing: 0;
		}

		#hero h2 {
			margin-top: 1px;
		}

	#pledges-counter {
		height: 285px;
	}

	#counter {
		width: 340px;
		height: 102px;
	}

	section .img img {
		width: 85px;
		height: 85px;
	}

	section #ask ul + p.ext-link {
		margin-top: 18px;
	}

	#form-instructions {
		display: flex;
		flex-direction: column;
	}

	#form-instructions h2 {
		margin: 0 auto;
		padding-left: 42px;
	}

	#form-instructions h2 + h3 {
		margin-bottom: 16px;
		max-width: none;
		text-align: center;
	}

	#form-instructions h2 span::before {
		/* transform: translate(-90%,-30%); */
		top: -21px;
		left: -45px;
		width: 45px;
		height: 78px;
	}

	.ext-link a {
		padding-left: 18px;
	}

		.ext-link a:before {
			width: 10px;
			height: 10px;;
		}

		.ext-link a span {
			border-bottom-width: 1px;
		}

	.pg footer {
		height: 264px;
	}
}


@media screen and (max-width: 1179px) {

	#xl {
		font-size: 14.1rem;
	}

	#hero h2 {
		font-size: 4.8rem;
	}
}

@media screen and (max-width: 1023px) {
	#hero {
		background-size: 780px;
	}

	#xl {
		font-size: 12.6rem;
	}

	#hero h2 {
		font-size: 4.41rem;
	}
}

@media screen and (max-width: 767px) {
	#hero .copy {
		margin-top: 9px;
	}

	#xl {
		font-size: 10.8rem;
	}

	#hero h2 {
		font-size: 3.3rem;
	}

	h2 .line-2 {
		display: inline-block;
		font-size: 3.9rem;
	}

	#pledges-counter {
		height: 210px;
		padding-top: 15px;
	}
}



/* misc */
.lds-dual-ring {
	display: inline-block;
	width: 36px;
	height: 36px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 30px;
	height: 30px;
	margin: 1px;
	border-radius: 50%;
	border: 3px solid var(--white);
	border-color: var(--white) transparent var(--white) transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
