import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics';
//import 'firebase/compat/storage'

// prod / default
const firebaseConfig = {
	apiKey: "AIzaSyB95gK8LL0YMCJ4dRkfM8ogLGNyw4_ny78",
	authDomain: "pledgetoask.firebaseapp.com",
	projectId: "pledgetoask",
	storageBucket: "pledgetoask.appspot.com",
	messagingSenderId: "666653106878",
	appId: "1:666653106878:web:6ce217afb7dc7336ddf4ef",
	measurementId: "G-SYZ4H3DLXX"
}

// development
// const firebaseConfig = {
// 	apiKey: "AIzaSyAHh5yZq0VKrm3bnprFrJkxwfpO3mQrZ1k",
// 	authDomain: "pledgetoask-dev.firebaseapp.com",
// 	projectId: "pledgetoask-dev",
// 	storageBucket: "pledgetoask-dev.appspot.com",
// 	messagingSenderId: "711443272035",
// 	appId: "1:711443272035:web:5a00c9f8470a98be8b0356",
// 	measurementId: "G-CVSJRMGVWR"
// }


firebase.initializeApp(firebaseConfig)
firebase.analytics()

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
//const projectStorage = firebase.storage()
const field_value = firebase.firestore.FieldValue
const timestamp = field_value.serverTimestamp
const increment = field_value.increment(1)
const decrement = field_value.increment(-1)


export { 
	projectAuth, 
	projectFirestore,
	//projectStorage,
	timestamp,
	increment,
	decrement,
	field_value
}