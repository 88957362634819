<template>
	<div id="home" class="pg"><!--  v-if="access === 'granted'" -->
		<header>
			<div id="logo">
				<h1>
					Northwell Raise Health
				</h1>
			</div>
		</header>
		<main>
			<div id="hero">
				<div class="container" v-if="!pledgeModal.showDiv">
					<div class="copy">
						<div id="xl">
							Pledge to Ask
						</div>
						<h2>
							<span class="txt">
								<span class="q">&ldquo;</span>Is there an unlocked</span> <span class="line-2">gun in the house?</span><span class="q">&rdquo;</span>
						</h2>
					</div>
				</div>
			</div>
			<div id="pledge">
				<div id="pledge-button-container" class="container transition"
					v-bind:class="{
						'fade-button': pledgeCounted.classOn
					}" 
					v-if="!pledgeCounted.removeDiv"
				>
					<button id="i-pledge" v-on:click="recordPledge" v-if="showsRec.length > 0">
						<div v-if="!isLoading">
							<span>I PLEDGE</span>
							<span v-if="concert === true">+ Get a chance to meet Aly & AJ! *</span>
						</div>
						<div v-if="isLoading" class="lds-dual-ring"></div>
					</button>
				</div>
				<div id="pledge-chance-container" class="container transition"
					v-if="pledgeCounted.removeDiv && !pledgeModal.showDiv && !formSubmitted"
				>
					<a v-if="concert" href="#" v-on:click.prevent="toggleModal('open')">Get the chance to meet Aly & AJ</a>
					<a v-else href="#" v-on:click.prevent="toggleModal('open')">Sign up to stay informed</a>
				</div>
				<Teleport to="body">
					<div id="pledge-modal-container" class="container"
					v-bind:class="{
						'concert-happening': concert
					}"
					v-show="pledgeModal.showDiv">
						<div id="pledge-modal-close" v-bind:class="{
							'show-overlay': pledgeModal.classOn
						}" v-on:click="toggleModal('close')">
							<span></span>
							<span></span>
						</div>
						<div id="pledge-modal-inner" v-bind:class="{
							'show-overlay': pledgeModal.classOn,
							'response-msg': formSubmitted
						}"
						class="transition">
							<div id="info-form">
								<div class="info">
									<div v-if="!formSubmitted" id="form-instructions">
										<h2>
											<span>Thanks for joining<br /> us in the mission!</span>
										</h2>
										<h3 v-if="concert">
											Enter your name, number and email so we can contact you when we announce who’s getting backstage.
										</h3>
										<h3 v-else>
											Want to stay involved? Share your contact info and we’ll keep you in the loop.
										</h3>
									</div>
									<div v-else id="form-submitted">
										<h2 v-if="concert">
											We got your info!
										</h2>
										<h2 v-else>
											Thank You!
										</h2>
										<p v-if="concert">
											At the end of the show, if you’ve won the drawing we’ll reach out with further instructions.<br/> Good luck!
										</p>
										<p v-else>
											We’ll keep in touch with info and updates on reducing and preventing gun violence.
										</p>
									</div>
								</div>
								<div v-if="!formSubmitted" class="form-container">
									<form v-on:submit.prevent="formEntry">
										<div class="field-group">
											<input placeholder="Name" id="name" v-model="name" type="text" minlength="3" required />
										</div>
										<div class="field-group">
											<input placeholder="888-888-8888" id="phone" v-model="phone" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required  @input="formatPhoneNumber" />
										</div>
										<div class="field-group">
											<input placeholder="Email" id="email" v-model="email" type="email" minlength="9" required />
										</div>
										<div v-if="!concert" class="field-group chkbox">
											<div id="i-agree">
												<label>
													<input v-model="agree" type="checkbox" />
													<span class="cb"></span>
													<span>I agree to the Terms and Conditions</span>
												</label>
												<div id="terms-container">
													<textarea rows="3">The Northwell Pledge to Ask about unlocked guns in the home is open only to legal residents of the United States who are at least thirteen (13) years of age or older.  By voluntarily providing your personal information, you (and, if an eligible minor, your parent or legal guardian) agree to the collection and use of your personal information by Northwell and its representatives, promotional agencies and marketing organization for the purpose of gun violence prevention efforts.  Your personal information may be used for future promotions and marketing of Northwell, its affiliates, representatives, and promotional agencies but will not be used for any other purpose nor will it otherwise be disclosed to third parties, unless otherwise required by law. If you (or, if an eligible minor, your parent or legal guardian) would like to be excluded from all lists used by Northwell for any future promotions and marketing by Northwell, you (or, if an eligible minor, your parent or legal guardian) may send a letter requesting that you be removed from such lists to Northwell at 2000 Marcus Avenue, New Hyde Park, NY 11042, ATTN: Marketing.</textarea>
												</div>
											</div>
										</div>
										<div class="field-group">
											<input v-model="bee" type="hidden" />
											<input v-model="concertId" type="hidden" />
											<button v-bind:disabled="isLoading || !agree">
												<span v-if="!isLoading">Submit</span> 
												<div v-if="isLoading" class="lds-dual-ring"></div>
											</button>
										</div>
									</form>
									<p class="notes" v-if="concert">
										Enter before the end of the show. Ages 13+. Data rates apply. No purchase necessary.<br/> See <a href="/pdf/Aly_AJ-Take-the-Pledge-Sweepstakes-3592093-1.pdf" target="_blank">Official Rules</a> for complete details.
									</p>
								</div>
							</div>
						</div>
					</div>
				</Teleport>
			</div>
			<div id="pledges-counter" v-bind:class="{
				'adjust-counter': formSubmitted,
				'animate-hands': animateHands
			}">
				<!-- <div class="loadHands"></div> -->
				<div class="container">
					<div id="pledges">
						<h5>
							PLEDGES*
						</h5>
						<div id="counter">
							<span class="o-text odometer odometer-theme-minimal zeros">
								<div class="odometer-inside">
									<span class="odometer-digit" v-for="(zero,idx) in howManyZeros" v-bind:key="idx">
										<span class="odometer-digit-spacer">8</span>
										<span class="odometer-digit-inner">
											<span class="odometer-ribbon">
												<span class="odometer-ribbon-inner">
													<span class="odometer-value">0</span>
												</span>
											</span>
										</span>
									</span>
								</div>
							</span>
							<Vue3Odometer
								v-if="counter != null"
								class = "o-text"
								v-bind:value ="Number(counter)"
								v-bind:theme ="'minimal'"
								v-bind:format ="'d'"
							/>
						</div>
					</div>
				</div>
			</div>
			<div id="additional-info">
				<section>
					<div class="hdr container">
						<h3>
							Gun injuries are the<br /> #1 cause of death for children. 
						</h3>
						<p>
							Northwell Health, New York’s largest healthcare provider, believes gun violence is a public health crisis. As part of our efforts to raise health for all, we work to break the taboo of talking about access to unlocked guns, and motivate people to take action to help prevent gun violence.
						</p>
					</div>
					<div id="ask" class="container">
						<div class="img">
							<img src="@/assets/svg/question.svg" width="200" height="200" />
						</div>
						<div class="copy">
							<h2>
								How to Start Asking
							</h2>
							<h3>
								Talking about guns can be a loaded topic,<br /> Here are some tips for bringing it up:
							</h3>
							<ul>
								<li>
									Say your doctor asked
								</li>
								<li>
									Do it via text or DM
								</li>
								<li>
									Say you saw it on TV
								</li>
							</ul>
							<p class="ext-link">
								<a href="https://www.northwell.edu/lock-guns" target="_blank">
									<span>More Details</span>
								</a>
							</p>
						</div>
					</div>
				</section>
				<section>
					<div id="stats" class="container">
						<div class="img">
							<img src="@/assets/svg/percent.svg" width="200" height="200" />
						</div>
						<div class="copy">
							<h2>
								STATS
							</h2>
							<h3>
								The numbers don’t lie
							</h3>
							<ul>
								<li>
									An estimated 4.6MM kids live with unlocked, loaded guns
								</li>
								<li>
									85% Of Younger Children Who Are Killed By Firearms<br> Are Killed In Their Own Home
								</li>
								<li>
									Locking up guns & ammo reduces risk of<br/> injury to kids by 85%
								</li>
							</ul>
						</div>
					</div>
				</section>
				<section>
					<div id="more" class="container">
						<div class="img">
							<img src="@/assets/svg/search.svg" width="200" height="200" />
						</div>
						<div class="copy">
							<h2>
								Learn Even More
							</h2>
							<h3>
								Links for further investigation
							</h3>
							<ul class="bull ext-link">
								<li>
									<a href="https://thewell.northwell.edu/culturally-relevant-stories/gun-violence" target="_blank">
										<span>Afraid To Ask About Guns? Read This</span>
									</a>
								</li>
								<li>
									<a href="https://thewell.northwell.edu/parenting/gun-safety-in-the-home" target="_blank">
										<span>Shedding The Stigma Of Gun Safety Conversations</span>
									</a>
								</li>
								<li>
									<a href="https://thewell.northwell.edu/culturally-relevant-stories/adressing-a-gun-in-the-house" target="_blank">
										<span>Addressing An Unsecured Gun In The House</span>
									</a>
								</li>
								<li>
									<a href="https://preventgunviolence.com/" target="_blank">
										<span>Annual Gun Violence Prevention Forum</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</main>
		<footer>
			<div id="social" class="container">
				<ul id="social-container">
					<li>
						<a href="https://www.facebook.com/northwellhealth" target="_blank"><img width="87" height="87" src="@/assets/svg/social-facebook.svg" alt="facebook" /></a>
					</li>
					<li>
						<a href="https://www.twitter.com/northwellhealth" target="_blank"><img width="87" height="87" src="@/assets/svg/social-twitter.svg" alt="twitter" /></a>
					</li>
					<li>
						<a href="https://instagram.com/northwellhealth" target="_blank"><img width="87" height="87" src="@/assets/svg/social-instagram.svg" alt="instagram" /></a>
					</li>
				</ul>
			</div>
			<div class="container">
				<p>
					<span>&copy;</span> Northwell Health 2023 
				</p>
			</div>
		</footer>
	</div>
</template>
<script>
import dayjs from 'dayjs'
import Vue3Odometer from 'vue3-odometer'


export default {
	name: 'HomeView',
	components: {
		Vue3Odometer
	},
	data() {
		return {
			access: this.$route.query.access,
			animateHands: false,
			isLoading: false,
			speed: {
				a: 1260,
				b: 1369,
				c: 260,
				d: 369
			},
			concert: false,
			userPledged: 0,
			pledgeCounted: {
				classOn: false,
				removeDiv: false
			},
			pledgeModal: {
				classOn: false,
				showDiv: false
			},
			formSubmitted: false,
			bee: null,
			name: null,
			email: null,
			phone: null,
			date: null,
			agree: null,
			concertId: null
		}
	},
	watch: {
		concert(newVal,oldVal) {
			if(newVal !== oldVal){
				if(newVal === false){
					this.agree = false
				}
			}
		}
	},
	computed: {
		counter(){
			let the_count = this.$store.getters.counterVal + this.userPledged;
			return the_count != 0 ? the_count : '0';
		},
		howManyZeros(){
			return 6 - Number(String(this.counter).length)
		},
		currentDate(){
			const date = new Date();
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		showsRec(){
			return this.$store.getters.showsSingle
		}
	},
	methods: {
		showHappening(){
			const now = new Date();
			const timezoneOffsetMinutes = now.getTimezoneOffset()
			const pacific_offset = 7;
			const timezoneOffsetHours = pacific_offset - (timezoneOffsetMinutes / 60);
			let show_rec = null
			let dateTime = {
				date: dayjs().format('YYYY-MM-DD'),
				time: dayjs().format('HH:mm')
			}

			let date_offset = dayjs(`${dateTime.date}T${dateTime.time}`).subtract(timezoneOffsetHours,'hours').format('YYYY-MM-DDTHH:mm')
			dateTime.date = dayjs(date_offset).format('YYYY-MM-DD')
			dateTime.time = dayjs(date_offset).format('HH:mm')

			if(this.showsRec[0] != null){
				show_rec = this.showsRec[0]['shows'].find(show => show.date === dateTime.date)

				let the_time_end,
					the_time_start

				if(show_rec){
					the_time_end = dayjs(`${dateTime.date}T${show_rec.timeEnd}`).subtract(7 - show_rec.offset,'hours').format('YYYY-MM-DDTHH:mm')

					the_time_start = dayjs(`${dateTime.date}T${show_rec.timeStart}`).subtract(7 - show_rec.offset,'hours').format('YYYY-MM-DDTHH:mm')
				}

				if(show_rec && 
					date_offset <= the_time_end &&
					date_offset >= the_time_start ||
					show_rec != undefined && show_rec.override === true
				){ // show during time
					this.agree = true
					this.concert = true
					this.concertId = show_rec._altid
				// *to test non-concert*
					// this.concert = false
					// this.concertId = '---'
				} else { // no shows during time
					this.concert = false
					this.concertId = '---'
				}
			}
		},
		async loadCounter() {
			await this.$store.dispatch('loadCounter')
		},
		async loadShows() {
			await this.$store.dispatch('loadShows')
		},
		async recordPledge() {
			this.isLoading = true
			//this.animateHands = true

			await this.$store.dispatch('addPledge').then(() => {

				this.userPledged = 1
				this.pledgeCounted.classOn = true

				setTimeout(() => {
					this.isLoading = false
					this.pledgeModal.showDiv = true
					//this.animateHands = false
				},this.speed.a);

				

				setTimeout(() => {
					this.pledgeModal.classOn = true
					this.pledgeCounted.removeDiv = true
				},this.speed.b);
			})
		},
		formatPhoneNumber() {
			let phone = this.phone;
				phone = phone.replace(/[^0-9-]/g, '');

			const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);

			if (match) {
				this.phone = `${match[1]}-${match[2]}-${match[3]}`;
			} else {
				this.phone = phone;
			}
		},
		async formEntry(){
			this.isLoading = true
			
			let entry_obj = {
				name: this.name,
				email: this.email,
				phone: this.phone,
				concertId: this.concertId,
				date: this.currentDate,
				agree: this.agree,
				bee: this.bee
			}

			if(this.bee != null){
				this.isLoading = false
				return false
			}

			if(entry_obj.name == null || entry_obj.email == null || entry_obj.phone == null ){
				this.isLoading = false
				return false
			}

			try {
				await this.$store.dispatch('saveEntry',entry_obj).then(response => {
					if(response.msg === 'success'){
						this.formSubmitted = true
					} else {
						alert('error FE1 occured, please try again')
					}
				})
			}
			catch(err) {
				alert('error FE2 has occured, please try again')
			}
		},
		toggleModal(action){
			setTimeout(() => {
				if(action === 'open') this.pledgeModal.showDiv = !this.pledgeModal.showDiv
				if(action === 'close') this.pledgeModal.classOn = !this.pledgeModal.classOn
			},this.speed.c);

			setTimeout(() => {
				if(action === 'open') this.pledgeModal.classOn = !this.pledgeModal.classOn
				if(action === 'close') this.pledgeModal.showDiv = !this.pledgeModal.showDiv
			},this.speed.d);
		}
	},
	async created(){
		let timeDateCheck = setInterval(() => {
			this.showHappening()
		},333);

		await this.loadShows().then(() => {
			timeDateCheck
		})

		await this.loadCounter()
	}
}
</script>
<style>
.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	position: relative;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	*zoom: 1;
	*display: inline;
	visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
	text-align: left;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
	display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
	display: block;
	-webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
	display: block;
	-webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
	position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
	-webkit-transition: -webkit-transform 2s;
	-moz-transition: -moz-transform 2s;
	-ms-transition: -ms-transform 2s;
	-o-transition: -o-transform 2s;
	transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
	-webkit-transition: -webkit-transform 2s;
	-moz-transition: -moz-transform 2s;
	-ms-transition: -ms-transform 2s;
	-o-transition: -o-transform 2s;
	transition: transform 2s;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

#counter {
	display: flex;
	justify-content: center;
}

.odometer-digit {
	min-width: 60px;
	height: 114px;
}

	.odometer-digit-inner {
		line-height: 120px;
	}

		.odometer-value {
			margin-left: 14px;
		}


		@media screen and (max-width: 1023px) {
			.odometer-digit {
				min-width: 40px;
				height: 91px;
			}

			.odometer-digit-inner {
				line-height: 105px;
			}

			.odometer-value {
				margin-left: 11px;
			}
		}

.fade-button #pledge-button-container {
	opacity: 0;
}

.transition {
	transition: all 333ms ease-in-out;
}



/* social */
#social-container {
	display: flex;
	justify-content: center;
	margin: 25px auto 33px;
	gap: 65px;
}

@media screen and (max-width: 1023px) {
	#social-container {
		gap: 42px;
		margin: 0 auto 30px;
	}

	#social-container img {
		width: 52px;
		height: 52px;
	}
}
</style>