import { createStore } from 'vuex'
import { projectFirestore,projectAuth,increment,timestamp} from '../firebase'
//,timestamp,field_value
const axios = require('axios').default

export default createStore({
	state: {
		user: null,
		shows: [],
		showsSnap: null,
		counter: 0,
		counterSnap: null,
		winners: []
	},
	getters: {
		counterVal: state => {
			return state.counter
		},
		loggedInUser: state => {
			return state.user
		},
		showsSingle: state => {
			return state.shows
		},
		winners: state => {
			return state.winners
		}
	},
	mutations: {
		defineUser(state,payload){
			state.user = payload
		},
		setShows(state,payload){
			state.shows = payload
		},
			addShowsSnap(state,payload){
				state.showsSnap = payload
			},
		setCounter(state,payload){
			state.counter = payload
		},
			addCounterSnap(state,payload){
				state.counterSnap = payload
			},
		setWinners(state,payload){
			let randomIndex = Math.floor(Math.random() * payload.length)
			let winnersRec = state.winners.find(obj => obj.id === payload[randomIndex].id)

			if(winnersRec == null){
				if(payload[randomIndex] != undefined) state.winners.push(payload[randomIndex])


				if(payload[randomIndex] != undefined){
					delete payload[randomIndex].dateCreated
					payload[randomIndex].dateSelected = timestamp()
					try {
						projectFirestore.collection("selections").add(payload[randomIndex]).then(() =>{
							console.log('added!')
						})
					}
					catch(error){
						console.log(error)
					}
				}
			}
		}
	},
	actions: {
		async userLogin(context,payload){ 
			return await projectAuth.signInWithEmailAndPassword(payload.email,payload.password).then(() => {
				var returnObj = {
					'msg': 'success',
					'code': null
				}

				return returnObj
			})
			.catch((error) => {
				var returnObj = {
					'msg': error.message,
					'code': error.code
				}

				return returnObj;
			});
		},
		async logOut(){ // context
			var unsubscribe_arr = []

			if(this.state.showsSnap !== null){
				unsubscribe_arr.push(this.state.showsSnap())
			}

			Promise.all(unsubscribe_arr).then(() => {
				projectAuth.signOut().then(() => {
				}).catch((error) => {
					alert(error)
				})
			});
		},
		async loadAuth(context){
			let userProfile = null
			try {
				await projectAuth.onAuthStateChanged((user) => {
					if (user) {
						projectFirestore.collection('users')
							.where("uid","==",user.uid)
							.get()
							.then((profile) => { 
								userProfile = {
									uid: user.uid,
									email: user.email,
									access: profile.docs[0].data().access
								}
								context.commit('defineUser',userProfile)
							})
					} else {
						context.commit('defineUser',userProfile)
					}
				})
			}
			catch(err){
				alert(err)
			}
		},
		async loadCounter(context){ //  payload
			try {
				const unsubscribe = await projectFirestore.collection('counters')
				.doc('---stats---')
				.onSnapshot(snap => {
					context.commit('setCounter',snap.data().total)
					context.commit('addCounterSnap',unsubscribe)
				})
			}
			catch(err){
				alert(err)
			}
		},
		async loadShows(context){ // payload
			try {
				const unsubscribe = await projectFirestore.collection('shows')
				.where("_altid","==","i7kmGUYvLnmekLgjvzuRC")
				.onSnapshot(snap => {
					let docs = snap.docs.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					context.commit('setShows',docs)
					context.commit('addShowsSnap',unsubscribe)
				})
			}
			catch(err){
				alert(err)
			}
		},
		async saveShows(context,payload){
			payload.dateModified = timestamp()

			try {
				return await projectFirestore.collection('shows')
				.doc(payload.id)
				.update({
					"dateModified": payload.dateModified,
					"shows": payload['shows']
				}).then(() => {
					var returnObj = {
						'msg': 'success'
					}

					return returnObj
				})
			}
			catch(err){
				alert(err)
			}
		},
		async addPledge(){ // context,payload

			//dev
			//let shard_count = 6
			//prod
			let shard_count = 33

			let rand_no = Math.floor(Math.random() * (shard_count - 1 + 1)) + 1;
			let whichShard = projectFirestore.collection('counters')
				.doc('---stats---')
				.collection('shards')
				.doc('cnt-' + rand_no.toString().padStart(3, '0'))
			let newPledge = projectFirestore.collection("counters").doc()
			let batch = projectFirestore.batch();

			batch.update(whichShard, {
				count: increment,
			},{ 
				merge: true 
			})

			batch.set(newPledge, {
				dateCreated: timestamp()
			})

			try {
				await batch.commit().then(() => {
					var returnObj = {
						'msg': 'success'
					}

					return returnObj
				})
			}
			catch (error) {
				//console.log(error)
			}
		},
		saveEntry(context,payload){
			return new Promise((resolve, reject) => {

				delete payload.bee
				payload.dateCreated = timestamp()

				try {
					projectFirestore.collection("entries").add(payload).then(() =>{

						axios({
							method: 'post',
							url: 'https://us-central1-pledgetoask.cloudfunctions.net/sendThankYou',
							data: {
								emailAddr: payload.email,
								cd: '961afaf2a2ab607de766d7ef62f0dc7d'
							}
						}).then(function (res) {
							console.log(res.data)
						})

//https://us-central1-pledgetoask-dev.cloudfunctions.net/sendThankYou
						resolve({'msg': 'success'})
					})
				}
				catch(error){
					var returnObj = {
						'msg': error.message,
						'code': error.code
					}

					reject(returnObj)
				}
			})
		},
		async findEntries(context,payload){
			try {
				await projectFirestore.collection('entries')
				.where("concertId","==",payload)
				.get()
				.then((entries) => {
					let docs = entries.docs.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					context.commit('setWinners',docs)
				})
			}
			catch(err){
				alert(err)
			}
		}

	},
	modules: {
	}
})
